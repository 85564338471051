import { App, Button, Card, DatePicker, Form, Input, InputNumber, Modal, Select } from 'antd';
import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import useInterval from '../hooks/useInterval';
import { cloneDeep } from 'lodash';
import { useTranslation } from 'react-i18next';
import TableCard from '../components/table-card';
import { PROJECT_TABLE } from '../components/project-table';
import { PROJECT_USER_TABLE } from '../components/project-user-table';
import { ACTION_EVENT } from '../consts/action-event';
import { AppContext } from '../contexts/AppContext';
import { useTable } from '../hooks/useTable';
import APIList from '../http/ApiList';
import aipskdRoute from '../routes/index';

export const OPERATION = {
  ADD: 'add',
  MODIFY: 'modify',
  MEMBER: 'member',
};

const ProjectPage: React.FC = () => {
  const { message } = App.useApp();
  // context
  const { authInfo, setGlobalEvent, projectAdminAuth, setSelectOptions, authentication } = useContext(AppContext);

  // locales
  const { t } = useTranslation();

  // modal
  const [operation, setOperation] = useState<string>();
  const [isMemberModalOpen, setMemberModalOpen] = useState(false);
  const [projectRoles, setProjectRoles] = useState([]);

  // delete modal
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [namePlaceholder, setNamePlaceholder] = useState('');
  const [deleteItem, setDeleteItem] = useState<any>();
  const [inputName, setInputName] = useState('');
  const [deleteDisabled, setDeleteDisabled] = useState<boolean>(true);
  const [selectedProject, setSelectedProject] = useState(null);

  // form
  const [time, setTime] = useState(null);

  // interval
  useInterval(
    () => {
      getProjects(false);
    },
    time,
    false
  );

  useEffect(() => {
    setTableLoading(true);
    getProjects();
    // getProjectRoles();
  }, [authentication]);

  const getProjectRoles = () => {
    APIList.getProjectRoles()
      .get()
      .then((res: any[]) => {
        setProjectRoles(res);
      });
  };

  // modal function
  const showModal = (op: string, record?: any) => {
    setOperation(op);
    if (op === OPERATION.MEMBER) {
      setMemberModalOpen(true);
      setSelectedProject(record);
    }
  };

  const handleMemberModalCancel = () => {
    setMemberModalOpen(false);
  };

  // delete modal function
  const deleteModalReset = () => {
    setInputName('');
    setDeleteDisabled(true);
    setDeleteModalOpen(false);
  };

  const showDeleteModal = (record?: any) => {
    setNamePlaceholder(record.projectId);
    setDeleteItem(record);
    setDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    deleteModalReset();
  };

  const handleInputChange = (event) => {
    setInputName(event.target.value);
    if (event.target.value === deleteItem.projectId) {
      setDeleteDisabled(false);
    } else {
      setDeleteDisabled(true);
    }
  };

  const handleDelete = (record?: any) => {
    deleteDataHandler(record);
    deleteModalReset();
  };

  const deleteDataHandler = (record: any) => {
    setTableData(
      cloneDeep(
        property.data.map((item) => {
          if (record.projectId === item.projectId) {
            item.closeProjectStatus = 0;
          }
          return item;
        })
      )
    );
    setTime(5000);
    APIList.closeProject()
      .post({
        projectId: record.projectId,
      })
      .then(({ id, status, workflowName }: any) => {
        setTableData(
          cloneDeep(
            property.data.map((item) => {
              if (record.projectId === item.projectId) {
                item.closeProjectStatus = status;
                item.jobId = id;
                item.workflowName = workflowName;
              }
              return item;
            })
          )
        );
        getProjects();
      })
      .catch((error) => {
        setTableData(
          cloneDeep(
            property.data.map((item) => {
              if (record.projectId === item.projectId) {
                item.closeProjectStatus = null;
              }
              return item;
            })
          )
        );
        // message.error(error);
      });
    setSelectOptions(null);
  };

  // table function
  const tableTop = 206;
  const [property, setTableData, setSelectedRowKeys, setTableLoading] = useTable({
    options: PROJECT_TABLE,
    tableTop,
    showModal,
    showDeleteModal,
    customProperty: { projectAdminAuth, setTime },
  });

  const modalHeight = window.innerHeight - 200;
  const modalWidth = window.innerWidth * 0.6;

  const saveMemberRole = (data: any[], record: any) => {
    setUserTableLoading(true);
    APIList.createOrUpdateMemberRole()
      .post({
        userId: record.userId,
        projectId: selectedProject.projectId,
        projectRoleId: record.projectRoleId,
      })
      .then(() => {
        setUserTableData(data);
        if (authInfo.user.attributes.sub === record.awsUserId) {
          setGlobalEvent({
            eventType: ACTION_EVENT.AUTH_UPDATE,
          });
        }
        message.success('Updated');
      })
      .finally(() => {
        setUserTableLoading(false);
      });
  };

  const getMembers = () => {
    APIList.getMembers()
      .get({
        moduleName: aipskdRoute.key,
        projectId: selectedProject.projectId,
      })
      .then((data: any) => {
        if (data) {
          setUserTableData(data);
        }
      })
      .finally(() => {
        setUserTableLoading(false);
      });
  };

  useEffect(() => {
    if (isMemberModalOpen && selectedProject) {
      setUserTableLoading(true);
      getMembers();
    }
  }, [isMemberModalOpen, selectedProject]);

  const [userProperty, setUserTableData, setUserSelectedRowKeys, setUserTableLoading] = useTable({
    options: PROJECT_USER_TABLE,
    tableTop: 340,
    onChange: ({ data, record, key }) => {
      saveMemberRole(data, record);
    },
    customProperty: {
      projectRoles,
    },
  });

  const getProjects = (showLoading = true) => {
    if (!authentication) {
      return;
    }
    if (showLoading) {
      setTableLoading(true);
    }
    APIList.getProjects()
      .get()
      .then((data: any) => {
        if (data) {
          const jobStatus = data.map((item: any) => item.closeProjectStatus);
          const { systemRole, appGroups } = authentication;
          setTableData(
            data
              .filter((item) => item.closeProjectStatus !== 3)
              .map((item) => ({
                key: item.projectId,
                ...item,
                duration: computeDuration(item),
                projectRole:
                  systemRole === 'ADMIN'
                    ? 'ADMIN'
                    : appGroups.find((role) => role.projectId === item.projectId)?.projectRole?.toLocaleUpperCase(),
              }))
          );
          if (jobStatus.every((status) => status !== 1 && status !== 3)) {
            setTime(null);
          } else {
            setTime(5000);
          }
        }
        setTableLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setTableLoading(false);
      });
  };

  const computeDuration = (item) => {
    const endDate = dayjs(item.endDate).format('YYYYMMDD');
    const feedStartDate = dayjs(item.feedStartDate).format('YYYYMMDD');
    const epcStartDate = dayjs(item.epcStartDate).format('YYYYMMDD');
    let startDate = '';
    if (feedStartDate === '' && epcStartDate === '') {
      return '-';
    }
    if (feedStartDate === '' && epcStartDate !== '') {
      startDate = epcStartDate;
    } else if (feedStartDate !== '' && epcStartDate === '') {
      startDate = feedStartDate;
    } else if (epcStartDate < feedStartDate) {
      startDate = epcStartDate;
    } else {
      startDate = feedStartDate;
    }
    return (Math.ceil(dayjs(endDate, 'YYYYMMDD').diff(dayjs(startDate, 'YYYYMMDD'), 'months', true) * 2) / 2).toFixed(1);
  };

  return (
    <>
      <Card
        title={t('aipskd.project.list')}
        styles={{
          header: { minHeight: 46, fontSize: 20, fontWeight: 900 },
        }}
        className="list-card"
      >
        <TableCard {...property} />
      </Card>
      <Modal
        title={t('aipskd.project.close')}
        open={isDeleteModalOpen}
        onCancel={() => {
          handleDeleteCancel();
        }}
        destroyOnClose
        maskClosable={false}
        footer={[
          <div key="deleteGroup" style={{ textAlign: 'center' }}>
            <Button key="cancel" onClick={handleDeleteCancel}>
              {t('common.cancel')}
            </Button>
            <Button
              key="close"
              type="primary"
              onClick={() => handleDelete(deleteItem)}
              disabled={deleteDisabled}
              style={{ marginLeft: 30 }}
            >
              {t('aipskd.project.closeButton')}
            </Button>
          </div>,
        ]}
      >
        <p style={{ whiteSpace: 'pre-line' }}>{t('aipskd.project.closeConfirmation').replace('{placeholder}', namePlaceholder)}</p>
        <Input placeholder={namePlaceholder} value={inputName} onChange={handleInputChange} />
      </Modal>
      <Modal
        title="Member Settings"
        width={modalWidth}
        centered
        destroyOnClose
        styles={{
          body: { maxHeight: modalHeight },
        }}
        open={isMemberModalOpen}
        maskClosable={false}
        footer={null}
        onCancel={() => {
          handleMemberModalCancel();
        }}
      >
        <TableCard {...userProperty} width={modalWidth - 58} />
      </Modal>
    </>
  );
};

export default ProjectPage;
