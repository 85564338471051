import { Button, Select, Card, Space, Tooltip, App, Tag } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../contexts/AppContext';
import { useLocation } from 'react-router-dom';
import useStorage from '../hooks/useStorage';
import APIList from '../http/ApiList';
import { useTranslation } from 'react-i18next';

type DataSwitcherProps = {
  children: React.ReactNode;
  title: string;
  readonly: boolean;
  optimizationSelected: any;
  collapsed?: boolean;
  multiple?: boolean;
};

const DataSwitcher: React.FC<DataSwitcherProps> = (props) => {
  // context
  const {
    projectEditorAuth,
    projectId,
    setProjectId,
    planId,
    setPlanId,
    optimizeId,
    setOptimizeId,
    optimizationId,
    setOptimizationId,
    optimizationIds,
    setOptimizationIds,
    disciplineIds,
    setDisciplineIds,
    cwaIds,
    setCwaIds,
    projectList,
    setProjectList,
    planList,
    setPlanList,
    optimizeList,
    setOptimizeList,
    optimizationList,
    setOptimizationList,
    disciplineList,
    setDisciplineList,
    cwaList,
    setCwaList,
    dataSection,
    setDataSection,
    selectOptions,
    setSelectOptions,
    authentication,
  } = useContext(AppContext);

  const { t } = useTranslation();
  const { message } = App.useApp();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { children, title, optimizationSelected, collapsed = true, multiple = true } = props;
  const { setItem, getItem } = useStorage();

  const [masterBtnLoading, setMasterBtnLoading] = useState(false);

  const [pageInit, setPageInit] = useState(false);

  const [disciplineListShow, setDisciplineListShow] = useState([]);

  const [cwaListShow, setCwaListShow] = useState([]);

  useEffect(
    () => () => {
      setDataSection(null);
      setProjectId(null);
      setPlanId(null);
      setOptimizeId(null);
      setOptimizationId(null);
      setOptimizationIds([]);
    },
    []
  );

  // reset discipline when prev settings changed
  useEffect(() => {
    setDisciplineListShow(
      optimizeId && (multiple ? optimizationIds.length !== 0 : optimizationId)
        ? disciplineList.filter((item) => optimizeId === item.optimizeId)
        : []
    );
  }, [disciplineList, optimizeId, optimizationId, optimizationIds]);

  useEffect(() => {
    setCwaListShow(optimizeId && (multiple ? optimizationIds.length !== 0 : optimizationId) ? cwaList : []);
  }, [cwaList, projectId, planId, optimizeId, optimizationId, optimizationIds]);

  const getComputeFilterForm = (
    { projectId, planId, optimizeId, optimizationId, optimizationIds }: any,
    { projects, plans, optimizes, optimizations }: any
  ) => {
    const { systemRole, appGroups } = authentication;

    let projectIdPre = projectId;
    let planIdPre = null;
    let optimizeIdPre = null;
    let optimizationIdPre = null;
    let optimizationIdsPre = [];
    if (projects.length === 0) {
      projectIdPre = null;
    }
    if (projects.findIndex((item) => item.projectId === projectIdPre) < 0) {
      projectIdPre = projects[0].projectId;
    }
    setProjectId(projectIdPre);
    setProjectList(
      projects.map((item) => ({
        ...item,
        projectRole:
          systemRole === 'ADMIN' ? 'ADMIN' : appGroups.find((role) => role.projectId === item.projectId)?.projectRole?.toLocaleUpperCase(),
      }))
    );
    const filterPlans = plans.filter((item) => item.projectId === projectIdPre);
    setPlanList(filterPlans);
    const findPlan = filterPlans.find((item) => planId === item.planId) || filterPlans.find((item) => dataSection?.planId === item.planId);
    if (!findPlan && filterPlans.length > 0) {
      planIdPre = filterPlans[0].planId;
    } else if (filterPlans.length === 0) {
      planIdPre = null;
    } else {
      planIdPre = findPlan.planId;
    }

    const filterOptimizes = optimizes.filter((item) => item.planId === planIdPre && !!planIdPre);
    setOptimizeList(filterOptimizes);
    const findOptimize =
      filterOptimizes.find((item) => optimizeId === item.optimizeId) ||
      filterOptimizes.find((item) => dataSection?.optimizeId === item.optimizeId);
    if (!findOptimize && filterOptimizes.length > 0) {
      optimizeIdPre = filterOptimizes[0].optimizeId;
    } else if (filterOptimizes.length === 0) {
      optimizeIdPre = null;
    } else {
      optimizeIdPre = findOptimize.optimizeId;
    }

    // check job ready
    const optimize = optimizes.find((item: any) => item.optimizeId === optimizeIdPre);
    if (optimize?.originalJobReady === 0 && optimize?.optimizeJobReady === 0) {
      setOptimizationList([]);
      if (multiple) {
        optimizationIdsPre = [];
      } else {
        optimizationIdPre = null;
      }
    } else {
      const filterOptimizations = optimizations.filter((item) => optimizeIdPre === item.optimizeId);
      setOptimizationList(filterOptimizations);
      if (multiple) {
        let filterOptimization = filterOptimizations.filter((item) => optimizationIds.includes(item.optimizationId));
        if (filterOptimization.length === 0) {
          filterOptimization = filterOptimizations.filter((item) => (dataSection?.optimizationIds || []).includes(item.optimizationId));
        }
        if (filterOptimization.length === 0 && filterOptimizations.length > 0) {
          optimizationIdsPre = filterOptimizations.map((item) => item.optimizationId);
        } else if (filterOptimizations.length === 0) {
          optimizationIdsPre = [];
        } else {
          optimizationIdsPre = filterOptimization.map((item) => item.optimizationId);
        }
      } else {
        const findOptimization =
          filterOptimizations.find((item) => optimizationId === item.optimizationId) ||
          filterOptimizations.find((item) => dataSection?.optimizationId === item.optimizationId);
        if (!findOptimization && filterOptimizations.length > 0) {
          optimizationIdPre = filterOptimizations[0].optimizationId;
        } else if (filterOptimizations.length === 0) {
          optimizationIdPre = null;
        } else {
          optimizationIdPre = findOptimization.optimizationId;
        }
      }
    }

    return {
      projectIdComp: projectIdPre,
      planIdComp: planIdPre,
      optimizeIdComp: optimizeIdPre,
      optimizationIdComp: optimizationIdPre,
      optimizationIdsComp: optimizationIdsPre,
    };
  };

  useEffect(() => {
    if (!selectOptions || !pageInit) {
      return;
    }
    const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
      {
        projectId,
        planId,
        optimizeId,
        optimizationId,
        optimizationIds,
      },
      selectOptions
    );
    setProjectId(projectIdComp);
    setPlanId(planIdComp);
    setOptimizeId(optimizeIdComp);
    setOptimizationId(optimizationIdComp);
    setOptimizationIds(optimizationIdsComp);
  }, [projectId, planId, optimizeId, selectOptions]);

  /**
   * １．optimizeIdまたplanIdはRouterから取得
   * ２．optimizeIdはlocalStorageから取得
   * @returns
   */
  const getOptimizeInfo = () => {
    // routerから
    if (searchParams.get('planId')) {
      return {
        planId: searchParams.get('planId'),
      };
    }
    if (searchParams.get('optimizeId')) {
      return {
        optimizeId: searchParams.get('optimizeId'),
      };
    }
    if (searchParams.get('optimizationId')) {
      return {
        optimizationId: searchParams.get('optimizationId'),
      };
    }

    // local storageから
    let dataCrossSection: any = getItem('dataCrossSection', 'local');
    if (dataCrossSection) {
      dataCrossSection = JSON.parse(dataCrossSection);
      // if (dataCrossSection.optimize) {
      if (multiple) {
        return {
          localStorage: true,
          projectId: dataCrossSection.project,
          optimizeId: dataCrossSection.optimize,
          optimizationIds: dataCrossSection.optimizations,
        };
      }
      return {
        localStorage: true,
        projectId: dataCrossSection.project,
        optimizeId: dataCrossSection.optimize,
        optimizationIds: dataCrossSection.optimization
          ? [dataCrossSection.optimization]
          : dataCrossSection.optimizations && dataCrossSection.optimizations.length > 0
          ? [dataCrossSection.optimizations[0]]
          : [],
      };
      // }
    }
    return {};
  };

  useEffect(() => {
    getInitData();
  }, [authentication]);

  const getInitData = () => {
    if (!authentication) {
      return;
    }
    const { projectId: projectIdStorage, planId, optimizeId, optimizationId, optimizationIds, localStorage } = getOptimizeInfo();

    const setData = (projects, plans, optimizes, optimizations) => {
      let notAcceptFlag = false;
      if (!localStorage) {
        if (planId) {
          const { projectId } = plans.find((item: any) => item.planId === planId);
          const { optimizeId } = optimizes.find((item: any) => item.planId === planId);
          const optimizationIds = optimizations.filter((item: any) => item.optimizeId === optimizeId).map((item) => item.optimizationId);
          const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
            {
              projectId,
              planId,
              optimizeId,
              optimizationIds,
            },
            {
              projects,
              plans,
              optimizes,
              optimizations,
            }
          );
          setProjectId(projectIdComp);
          setPlanId(planIdComp);
          setOptimizeId(optimizeIdComp);
          setOptimizationIds(optimizationIdsComp);
          notAcceptFlag = true;
        }
        if (optimizeId) {
          const { projectId, planId } = optimizes.find((item: any) => item.optimizeId === optimizeId);
          const optimizationIds = optimizations.filter((item: any) => item.optimizeId === optimizeId).map((item) => item.optimizationId);
          const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
            {
              projectId,
              planId,
              optimizeId,
              optimizationIds,
            },
            {
              projects,
              plans,
              optimizes,
              optimizations,
            }
          );
          setProjectId(projectIdComp);
          setPlanId(planIdComp);
          setOptimizeId(optimizeIdComp);
          setOptimizationIds(optimizationIdsComp);
          notAcceptFlag = true;
        }
        if (optimizationId) {
          const { projectId, optimizeId } = optimizations.find((item: any) => item.optimizationId === optimizationId);
          const { planId } = optimizes.find((item: any) => item.optimizeId === optimizeId);
          const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
            {
              projectId,
              planId,
              optimizeId,
              optimizationId,
            },
            {
              projects,
              plans,
              optimizes,
              optimizations,
            }
          );
          setProjectId(projectIdComp);
          setPlanId(planIdComp);
          setOptimizeId(optimizeIdComp);
          setOptimizationId(optimizationIdComp);
          notAcceptFlag = true;
        }
      } else if (optimizeId && optimizationIds && optimizationIds.length > 0) {
        const checkOptimizationIds = optimizationIds.filter(
          (optimizationId) => optimizations.findIndex((item: any) => item.optimizationId === optimizationId) > -1
        );
        if (optimizes.findIndex((item: any) => item.optimizeId === optimizeId) > -1) {
          const { projectId, planId } = optimizes.find((item: any) => item.optimizeId === optimizeId);
          if (multiple) {
            if (!projectIdStorage || projectId === projectIdStorage) {
              const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
                {
                  projectId,
                  planId,
                  optimizeId,
                  optimizationIds: checkOptimizationIds,
                },
                {
                  projects,
                  plans,
                  optimizes,
                  optimizations,
                }
              );
              setProjectId(projectIdComp);
              setPlanId(planIdComp);
              setOptimizeId(optimizeIdComp);
              setOptimizationIds(optimizationIdsComp);
            } else {
              const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
                {
                  projectId: projectIdStorage,
                  planId: null,
                  optimizeId: null,
                  optimizationIds: [],
                },
                {
                  projects,
                  plans,
                  optimizes,
                  optimizations,
                }
              );
              setProjectId(projectIdComp);
              setPlanId(planIdComp);
              setOptimizeId(optimizeIdComp);
              setOptimizationIds(optimizationIdsComp);
            }
          } else if (!projectIdStorage || projectId === projectIdStorage) {
            const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
              {
                projectId,
                planId,
                optimizeId,
                optimizationId: checkOptimizationIds[0],
              },
              {
                projects,
                plans,
                optimizes,
                optimizations,
              }
            );
            setProjectId(projectIdComp);
            setPlanId(planIdComp);
            setOptimizeId(optimizeIdComp);
            setOptimizationId(optimizationIdComp);
          } else {
            const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
              {
                projectId: projectIdStorage,
                planId: null,
                optimizeId: null,
                optimizationId: null,
              },
              {
                projects,
                plans,
                optimizes,
                optimizations,
              }
            );
            setProjectId(projectIdComp);
            setPlanId(planIdComp);
            setOptimizeId(optimizeIdComp);
            setOptimizationId(optimizationIdComp);
          }
          notAcceptFlag = true;
        }
      }

      if (!notAcceptFlag) {
        let projectId;
        let planId;
        let optimizeId;
        let optimizationId;
        if (projectIdStorage) {
          projectId = projectIdStorage;
          const planData = plans.find((item: any) => item.projectId === projectId);
          planId = planData ? planData.planId : null;
          const optimizeData = optimizes.find((item: any) => item.planId === planId);
          optimizeId = optimizeData ? optimizeData.optimizeId : null;
          const optimizationData = optimizations.find((item: any) => item.optimizeId === optimizeId);
          optimizationId = optimizationData ? optimizationData.optimizationId : null;
        } else {
          const optimizationData = optimizations[0];
          projectId = optimizationData.projectId;
          optimizeId = optimizationData.optimizeId;
          optimizationId = optimizationData.optimizationId;
          const optimizeData = optimizes.find((item: any) => item.optimizeId === optimizeId);
          planId = optimizeData ? optimizeData.planId : null;
        }
        if (multiple) {
          const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
            {
              projectId,
              planId,
              optimizeId,
              optimizationIds: [optimizationId],
            },
            {
              projects,
              plans,
              optimizes,
              optimizations,
            }
          );
          setProjectId(projectIdComp);
          setPlanId(planIdComp);
          setOptimizeId(optimizeIdComp);
          setOptimizationIds(optimizationIdsComp);
        } else {
          const { projectIdComp, planIdComp, optimizeIdComp, optimizationIdComp, optimizationIdsComp } = getComputeFilterForm(
            {
              projectId,
              planId,
              optimizeId,
              optimizationId,
            },
            {
              projects,
              plans,
              optimizes,
              optimizations,
            }
          );
          setProjectId(projectIdComp);
          setPlanId(planIdComp);
          setOptimizeId(optimizeIdComp);
          setOptimizationId(optimizationIdComp);
        }
      }
      setPageInit(true);
    };

    // if (selectOptions) {
    //   const {
    //     projects, plans, optimizes, optimizations
    //   } = selectOptions;
    //   setData(projects, plans, optimizes, optimizations);
    // } else {
    Promise.all([
      APIList.getProjects().get(),
      APIList.getProjectPlans().get(),
      APIList.getOptimizes().get(),
      APIList.getOptimizations().post({}),
    ])
      .then(([projects, plans, optimizes, optimizations]: any[]) => {
        setSelectOptions({
          projects: projects.map((item) => ({
            ...item,
            key: item.projectId,
            value: item.projectId,
            label: item.projectId,
          })),
          plans: plans.map((item) => ({
            ...item,
            key: item.planId,
            value: item.planId,
            label: item.planName,
          })),
          optimizes: optimizes.map((item) => ({
            ...item,
            key: item.optimizeId,
            value: item.optimizeId,
            label: item.optimizeName,
          })),
          optimizations: optimizations.map((item) => ({
            ...item,
            key: item.optimizationId,
            value: item.optimizationId,
            label: item.optimizationName,
          })),
        });
        setData(projects, plans, optimizes, optimizations);
      })
      .catch((error) => {
        console.log(error);
        onFilterData();
      });
    // }

    APIList.getDisciplines()
      .get()
      .then((res: any[]) => {
        setDisciplineList(
          res.map((item) => ({
            ...item,
            value: item.disciplineId,
            label: item.disciplineId,
          }))
        );
      });

    APIList.getCwas()
      .get()
      .then((res: any[]) => {
        const cwas = [];
        res.forEach((item) => {
          cwas.push({
            ...item,
            value: item.cwaId,
            label: item.cwaName,
          });
        });
        setCwaList(cwas);
      });
  };

  useEffect(() => {
    if (selectOptions && pageInit) {
      onFilterData();
    }
  }, [selectOptions, pageInit]);

  const onFilterData = () => {
    setDataSection({
      projectId,
      planId,
      optimizeId,
      optimizationId,
      optimizationIds,
    });
    setOptimizesStorage();
  };

  const setOptimizesStorage = () => {
    let dataCrossSection: any = getItem('dataCrossSection', 'local');
    if (!dataCrossSection) {
      dataCrossSection = {};
    } else {
      dataCrossSection = JSON.parse(dataCrossSection);
    }
    if (multiple) {
      dataCrossSection.project = projectId;
      dataCrossSection.optimize = optimizeId;
      dataCrossSection.optimizations = optimizationIds;
    } else if (optimizeId) {
      dataCrossSection.project = projectId;
      dataCrossSection.optimize = optimizeId;
      dataCrossSection.optimization = optimizationId;
    }

    setItem('dataCrossSection', JSON.stringify(dataCrossSection), 'local');
  };

  const updateOptimizationMaster = () => {
    setMasterBtnLoading(true);
    APIList.updateOptimizationMaster()
      .put({
        optimizeId: optimizationSelected.optimizeId,
        optimizationId: optimizationSelected.optimizationId,
        optimizationName: optimizationSelected.optimizationName,
      })
      .then((res) => {
        APIList.getOptimizations()
          .post({})
          .then((optimizations: any[]) => {
            setSelectOptions({
              ...selectOptions,
              optimizations: optimizations.map((item) => ({
                ...item,
                key: item.optimizationId,
                value: item.optimizationId,
                label: item.optimizationName,
              })),
            });
          });
        message.success(t('aipskd.select.masterMessage'));
      })
      .finally(() => {
        setMasterBtnLoading(false);
      });
  };

  const dropdown = (
    <Space wrap>
      <div className="form-container" style={{ flexWrap: 'wrap' }}>
        <div className="form-item-vertical">
          <label>{t('aipskd.select.project')} </label>
          <span>
            <Select
              showSearch
              style={{ width: '180px' }}
              className="select-height-32"
              value={projectId}
              onChange={(value) => {
                setProjectId(value);
              }}
              placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.project'))}
              optionFilterProp="label"
              filterOption={(input, option) => ((option?.label as string) ?? '').includes(input)}
              filterSort={(optionA, optionB) =>
                ((optionA?.label as string) ?? '').toLowerCase().localeCompare(((optionB?.label as string) ?? '').toLowerCase())
              }
              options={projectList}
              optionRender={(option) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div style={{ flex: 1 }} className="ellipsis">
                    {option.data.label}
                  </div>
                  <Tag style={{ fontSize: 10 }}>{option.data.projectRole}</Tag>
                </div>
              )}
              labelRender={(props: any) => {
                const { systemRole, appGroups } = authentication;
                const { label, value } = props;
                if (label) {
                  return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} title={label}>
                      <div style={{ flex: 1 }} className="ellipsis">
                        {label}
                      </div>
                      <Tag style={{ height: 20, marginInlineEnd: 0 }}>
                        {systemRole === 'ADMIN'
                          ? 'ADMIN'
                          : appGroups.find((role) => role.projectId === value)?.projectRole?.toLocaleUpperCase()}
                      </Tag>
                    </div>
                  );
                }
                return null;
              }}
            />
          </span>
        </div>

        <div className="form-item-vertical">
          <label>{t('aipskd.select.planName')} </label>
          <span>
            <Select
              showSearch
              style={{ width: '180px' }}
              className="select-height-32"
              value={planId}
              onChange={(value) => {
                setPlanId(value);
              }}
              placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.planName'))}
              optionFilterProp="label"
              options={planList}
            />
          </span>
        </div>
        <div className="form-item-vertical">
          <label>{t('aipskd.select.optimize')} </label>
          <span>
            <Select
              showSearch
              style={{ width: '180px' }}
              className="select-height-32"
              value={optimizeId}
              onChange={(value: any) => {
                setOptimizeId(value);
              }}
              placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.optimize'))}
              optionFilterProp="label"
              options={optimizeList}
            />
          </span>
        </div>
        <div className="form-item-vertical">
          <label>{t('aipskd.select.optionName')} </label>
          <span>
            {multiple ? (
              <Select
                showSearch
                mode="multiple"
                maxTagCount={1}
                className="select-height-32"
                maxTagTextLength={6}
                style={{ width: '200px' }}
                value={optimizationIds}
                onChange={(value: any) => {
                  if (value && value.length > 0) {
                    setOptimizationIds(value);
                  }
                }}
                placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.optionName'))}
                optionFilterProp="label"
                options={optimizationList}
              />
            ) : (
              <Select
                showSearch
                style={{ width: '180px' }}
                className="select-height-32"
                value={optimizationId}
                onChange={(value: any) => {
                  setOptimizationId(value);
                }}
                placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', t('aipskd.select.optionName'))}
                optionFilterProp="label"
                options={optimizationList}
              />
            )}
          </span>
        </div>
        <div className="form-item-vertical">
          <label>Discipline </label>
          <span>
            <Select
              showSearch
              allowClear
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={6}
              style={{ width: '180px' }}
              className="select-height-32"
              value={disciplineIds}
              onChange={(value) => {
                setDisciplineIds(value);
              }}
              placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', '「Discipline」')}
              optionFilterProp="label"
              options={disciplineListShow}
            />
          </span>
        </div>
        <div className="form-item-vertical">
          <label>CWA </label>
          <span>
            <Select
              showSearch
              allowClear
              mode="multiple"
              maxTagCount={1}
              maxTagTextLength={6}
              style={{ width: '180px' }}
              className="select-height-32"
              value={cwaIds}
              onChange={(value) => {
                setCwaIds(value);
              }}
              placeholder={t('aipskd.common.selectPlaceholder').replace('{placeholder}', '「CWA」')}
              optionFilterProp="label"
              options={cwaListShow}
            />
          </span>
        </div>
        <Button
          style={{ marginLeft: '20px' }}
          type="primary"
          onClick={() => {
            onFilterData();
          }}
          disabled={multiple ? optimizationIds.length === 0 : !optimizationId}
        >
          {t('aipskd.select.select')}
        </Button>
        {projectEditorAuth(projectId) ? (
          <>
            <Button
              style={{ marginLeft: '20px' }}
              type="primary"
              disabled={!optimizationSelected || optimizationSelected.isMaster}
              loading={masterBtnLoading}
              onClick={() => {
                updateOptimizationMaster();
              }}
            >
              {t('aipskd.select.setAsMaster')}
            </Button>
          </>
        ) : (
          <></>
        )}
      </div>
    </Space>
  );

  return (
    <>
      <Card
        title={<Tooltip title={title}>{title}</Tooltip>}
        className="list-card"
        styles={{
          header: { minHeight: 46, fontSize: 20, fontWeight: 900 },
        }}
        style={{
          marginRight: collapsed ? 0 : 300,
          transition: 'all 0.2s',
        }}
        extra={dropdown}
      >
        {children}
      </Card>
    </>
  );
};

export default DataSwitcher;
