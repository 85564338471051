import { AppContext } from '../contexts/AppContext';
import { useContext } from 'react';

export const AUTH_OPEN_STATE_TYPE = {
  OPEN: 'OPEN',
  CLOSE: 'CLOSE',
  DEV: 'DEV',
};

const useRoleAuth = ({ authentication }) => {
  const AUTH_OPEN_STATE = AUTH_OPEN_STATE_TYPE.DEV;

  const isAdmin = () => {
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.OPEN) {
      return authentication && ['ADMIN'].includes(authentication.systemRole);
    }
    if (AUTH_OPEN_STATE === AUTH_OPEN_STATE_TYPE.CLOSE) {
      return false;
    }
    return true;
  };

  const checkRoleFunctionAuth = () => {
    if (isAdmin()) {
      return true;
    }
    return false;
  };

  return {
    checkRoleFunctionAuth,
    AUTH_OPEN_STATE,
  };
};

export default useRoleAuth;
