import { Select } from 'antd';
import React from 'react';
export const PROJECT_USER_TABLE = ({ t, tableData, tableTop, loading, showModal, showDeleteModal, onChange, customProperty }) => ({
  columns: PROJECT_USER_TABLE_COLUMNS({
    t,
    showModal,
    showDeleteModal,
    tableData,
    onChange,
    customProperty,
  }),
  data: tableData,
  height: window.innerHeight - tableTop,
  bordered: true,
  loading,
});

const PROJECT_USER_TABLE_COLUMNS = ({ t, showModal, showDeleteModal, tableData, onChange, customProperty }) => [
  {
    title: 'User Name', //t('aipskd.project.name'),
    dataIndex: 'userName',
    key: 'userName',
    width: 150,
    sorter: (a, b) => a.userName.localeCompare(b.userName),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Email', // t('aipskd.project.location'),
    dataIndex: 'email',
    key: 'email',
    width: 200,
    sorter: (a, b) => a.email.localeCompare(b.email),
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Project Role', // t('aipskd.project.scopeType'),
    dataIndex: 'projectRoleId',
    key: 'projectRoleId',
    width: 200,
    sorter: (a, b) => a.projectRoleId.localeCompare(b.projectRoleId),
    sortDirections: ['descend', 'ascend'],
    render: (text, record, index) => {
      const { projectRoles } = customProperty;
      return (
        <Select
          value={record.projectRoleId}
          style={{ width: '90%' }}
          onChange={(newValue: string) => {
            const data = tableData.map((item) => {
              if (item.userId === record.userId) {
                item.projectRoleId = newValue;
              }
              return item;
            });
            record.projectRoleId = newValue;
            onChange({ data, record, value: newValue, key: 'projectRoleId' });
          }}
          options={[
            { value: null, label: 'UNSET' },
            ...projectRoles.map((role) => ({
              key: role.projectRoleId,
              value: role.projectRoleId,
              label: role.projectRoleName,
            })),
          ]}
        />
      );
    },
  },
];
