import { useRef, useContext, useState, useEffect } from 'react';
import { AppContext } from '../contexts/AppContext';
import APIList from '../http/ApiList';
import Chat, { Bubble, useMessages } from '@chatui/core';
import { Input, Tooltip, Popconfirm, Spin } from 'antd';
import { CheckOutlined, CloseOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

type MemoProperties = {
  optimizationId: string;
  memoInfo: any;
};

const MemoContainer: React.FC<MemoProperties> = ({ optimizationId, memoInfo }) => {
  const { t } = useTranslation();

  const composerRef = useRef(null);
  const ref = useRef(null);

  const { authInfo, projectEditorAuth, dataSection } = useContext(AppContext);

  const [memoLoading, setMemoLoading] = useState(false);

  const [memoEditMsg, setMemoEditMsg] = useState<{ id: string; text: string; visible: boolean }>();

  const { messages, prependMsgs, appendMsg, updateMsg, deleteMsg, resetList } = useMessages([]);

  const iconStyle = {
    padding: 3,
    fontSize: 'small',
    color: '#555',
    flex: 1,
  };

  const getAlertMemo = () => {
    setMemoLoading(true);
    APIList.getAlertMemo()
      .get({ optimizationId, ...memoInfo, datePeriod: encodeURIComponent(memoInfo.datePeriod) })
      .then((data: any) => {
        if (data) {
          const memoData = data.map((item) => ({
            _id: item.id,
            type: 'text',
            content: {
              text: item.memo,
            },
            user: {
              role: authInfo.user.attributes.sub === item.userId ? 'User' : 'Others',
              name:
                authInfo.user.attributes.sub === item.userId ? `You ${item.updatedAt === item.createdAt ? '' : '--edited'}` : item.userName,
              updatedAt: new Date(item.updatedAt).toLocaleString('ja-JP', { hour12: false }),
            },
            position: 'left',
          }));
          prependMsgs(memoData);
        }
        setMemoLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setMemoLoading(false);
      });
  };

  const handleDeleteMsg = (msg) => {
    setMemoLoading(true);
    APIList.deleteAlertMemo()
      .del({ id: msg._id })
      .then(() => {
        deleteMsg(msg._id);
        // message.success('Successfully deleted the memo');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMemoLoading(false);
      });
  };

  const handleUpdateMsg = () => {
    setMemoLoading(true);
    const { id, text } = memoEditMsg;
    APIList.updateAlertMemo()
      .put({
        id,
        memo: text,
      })
      .then((data: any) => {
        updateMsg(id, {
          type: 'text',
          content: {
            text,
          },
          user: {
            role: 'User',
            name: 'You --edited',
            updatedAt: new Date(data.updatedAt).toLocaleString('ja-JP', { hour12: false }),
          },
          position: 'left',
        });
        // message.success('Successfully updated the memo');
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setMemoLoading(false);
      });
  };

  const handleSend = (type: string, val: string) => {
    setMemoLoading(true);
    if (type === 'text' && val.trim()) {
      APIList.createAlertMemo()
        .post({
          optimizationId,
          ...memoInfo,
          memo: val,
        })
        .then((data: any) => {
          appendMsg({
            _id: data.id,
            type: 'text',
            content: {
              text: val,
            },
            user: {
              role: 'User',
              name: 'You',
              updatedAt: new Date(data.updatedAt).toLocaleString('ja-JP', { hour12: false }),
            },
            position: 'left',
          });
          // message.success('Successfully created the memo');
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setMemoLoading(false);
        });
    }
  };

  const renderMessageContent = (msg) => {
    const { _id, content, user } = msg;

    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center' }} className={msg.user.role !== 'User' ? 'bubble-not-self' : ''}>
          <Bubble
            content={
              <>
                <span>
                  {memoEditMsg?.visible && _id === memoEditMsg?.id ? (
                    <div style={{ display: 'flex' }}>
                      <Input.TextArea
                        style={{ width: 350, height: 100 }}
                        placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.optionDetail.memo'))}
                        size="large"
                        value={memoEditMsg?.id === _id ? memoEditMsg?.text : ''}
                        onChange={(e) => {
                          setMemoEditMsg({
                            id: _id,
                            text: e.target.value,
                            visible: true,
                          });
                        }}
                      />
                      <Tooltip title={t('common.submit')}>
                        <CheckOutlined
                          style={{ ...iconStyle, marginLeft: 8 }}
                          onClick={() => {
                            setMemoEditMsg({
                              ...memoEditMsg,
                              visible: false,
                            });
                            updateMsg(_id, {
                              type: 'text',
                              user,
                              content: {
                                text: memoEditMsg.text,
                              },
                              position: 'left',
                            });
                            handleUpdateMsg();
                          }}
                        />
                      </Tooltip>
                      <Tooltip title={t('common.cancel')}>
                        <CloseOutlined
                          style={{ ...iconStyle, marginLeft: 5 }}
                          onClick={() => {
                            setMemoEditMsg({
                              ...memoEditMsg,
                              visible: false,
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                  ) : (
                    content.text
                  )}
                </span>
                <p className="extra">{`At: ${user?.updatedAt}`}</p>
              </>
            }
          />
          {msg.user.role === 'User' && projectEditorAuth(dataSection.projectId) ? (
            <>
              <Tooltip title={t('common.edit')}>
                <EditOutlined
                  style={iconStyle}
                  onClick={() => {
                    setMemoEditMsg({
                      id: _id,
                      text: content.text,
                      visible: !memoEditMsg?.visible,
                    });
                  }}
                />
              </Tooltip>
              <Popconfirm
                title={t('aipskd.optionDetail.deleteMemoConfirmMessage')}
                onConfirm={() => {
                  handleDeleteMsg(msg);
                }}
                okText={t('common.okText')}
                cancelText={t('common.cancelText')}
              >
                <Tooltip title={t('common.delete')}>
                  <DeleteOutlined style={iconStyle} />
                </Tooltip>
              </Popconfirm>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    composerRef.current.setText('');
    resetList([]);
    if (optimizationId && memoInfo) getAlertMemo();
  }, [optimizationId, memoInfo]);

  useEffect(() => {
    if (!projectEditorAuth(dataSection.projectId)) {
      const Composer = ref.current.querySelector('.Composer');
      if (Composer) {
        Composer.parentNode.removeChild(Composer);
      }
    }
  }, [dataSection]);

  return (
    <Spin spinning={memoLoading}>
      <Chat
        ref={ref}
        locale="en-US"
        placeholder={t('aipskd.common.inputPlaceholder').replace('{placeholder}', t('aipskd.optionDetail.memo'))}
        messages={messages}
        renderMessageContent={renderMessageContent}
        onSend={handleSend}
        composerRef={composerRef}
      />
    </Spin>
  );
};

export default MemoContainer;
